export default {
  pageTitle: "Commande d'adhésion",
  progressSteps: {
    version: "Version:",
    versionInfoText:
    " Health Info Net SA - ",
  },
  supportPanel: {
    remoteForm: {
      remoteMaintenance: "Maintenance à distance",
      sessionNumberLabel: "Veuillez saisir votre numéro de session",
      downloadBtn: "Télécharger",
    },
    customerService: {
      header: "Centre clients HIN",
      infoMail: " info{'@'}hin.ch",
      supportMail: " support{'@'}hin.ch",
      contactForm: "Formulaire de contact",
      contactFormUrl: "https://www.hin.ch/fr/contact/formulaire/",
      phoneSwiss: " 0848 830 741",
      phoneInternational: " +41 52 235 02 75",
      international: " (International)",
      adminRequest: " (Demandes administratives)",
      technicalRequest: " (Demandes techniques)",
      openingHoursTitle: "Horaires d’ouverture: ",
      openingHours: "Lundi au vendredi 08h00 à 18h00",
      openingHoursNoon: "",
      openingHoursAfternoon: "",
    },
    globalSupport: {
      header: "HIN Mail GLOBAL Support",
      receiverMail: "Pour destinataires d’e-mails cryptés",
      globalSupportMail: " global.support{'@'}hin.ch",
      globalSupportPhone: " 0848 22 44 11",
      globalSupportPhoneInternational: " +41 52 235 02 71",
    },
    adress: {
      header: "Adresse",
      companyName: "Health Info Net AG",
      street: "Seidenstrasse 4",
      zipCity: "8304 Wallisellen",
    },
  },
  appErrors: {
    backendCommunicationError:
      "Un problème de connexion est survenu avec le serveur." +
      " Veuillez réessayer plus tard." +
      " Si le problème persiste, veuillez contacter le {supportLink}.",
      failedBackendCall:
      "Validation impossible, veuillez vérifier votre connexion Internet.",
  },
  appComponents: {
    appHeaderTitle: "Souscrire une adhésion à HIN",
    inputTeamName: "Nom d’affichage",
    btnBack: "Retour",
    smallText:
      "Ce nom est affiché comme expéditeur de l’e-mail, p. ex. cabinet Modèle",
    inputFirstName: "Prénom",
    inputLastName: "Nom",
    inputEmail: "Adresse e-mail (actuelle)",
    cacheDataText: "Enregistrer l’état intermédiaire et continuer plus tard",
  },
  membersForm: {
    memBodyText:
      "La commande de votre adhésion à HIN comprend une {memTitle} que vous devez effectuer personnellement. La commande (y c. l’identification vidéo) prend environ 30 minutes.",
    memTitle: "Identification vidéo",
    memExplainText:
      "Vidéo explicative «Comment fonctionne l’identification vidéo?»",
    memExplainLink: "https://support.hin.ch/fr/identification-video/deroulement-de-lidentification-video/",
    memPricingLink: "https://www.hin.ch/fr/adhesion/adhesion-a-hin.cfm",
    memFooterTitle: "Sélectionner une association",
    firstInfoBoxText: "Pour les adhésions",
    secondInfoBoxText:
      "des règles spéciales s’appliquent, veuillez passer par le lien suivant pour passer votre commande:",
    orderLink: "Vue d’ensemble des produits",
    memListText: {
      header: "Il vous faut pour cela:",
      internetAccess: "Accès à Internet – bonne connexion durant tout le processus",
      mobilePhone: "Téléphone portable ou tablette / ordinateur avec webcam et microphone (navigateur: Google Chrome, Mozilla Firefox ou Microsoft Edge)",
      validId: "Document d’identification valide ",
      mobilePhoneAccess: "Accès à un téléphone portable",
      glnNumber: "Votre GLN (si utilisation du DEP souhaitée)",
      validDocs: "Documents d’identité admis:",
      idSwiss: "Personnes de nationalité suisse: carte d’identité suisse ou passeport suisse",
      idForeign: "Pour les ressortissants d'autres pays : un document d'identité valide du pays d'origine et un titre de séjour en règle",
    },
    memAssociation: {
      header: "Offres spéciales",
      overviewLink: "Aperçu Adhésion",
      specialCondition:
        "Si vous êtes membre d’une association, vous bénéficiez éventuellement de conditions spéciales (voir {overviewLink}), par exemple en ce qui concerne la cotisation annuelle et la terminaison de l’adresse e-mail {'(exemple: @association-hin.ch)'}.",
    },
    modalTitle: "Veuillez noter",
    modalMemberTitle: "Adhésion à HIN {association}",
    // removed {association} variable here due to text changes
    // code for using the variable is still available in case it is needed
    modalText:
      "Des conditions spéciales s’appliquent à cette offre. " +
      "Veuillez la commander via le lien suivant: ",
    modalTest:
      "Des conditions spéciales s’appliquent à cette offre. "
      +
      "Veuillez la commander via le lien suivant: ",
    modalTextUrl: "Commander une offre",
    modalDismissButton: "OK",
    selectionNoAssociation: "Pas d’association partenaire",
  },
  identityForm: {
    popoverTitle: "Veuillez sélectionner au moins une eID",
    personalCard: {
      persCardHeader: "Mon eID personnelle",
      compareToId:
        "Est comparable à un document d’identité, mais dans un contexte numérique",
      uniquePerson:
        "Une seule personne est autorisée à utiliser l’eID personnelle.",
      appAccess:
        "Accès aux applications contenant des données sensibles (p. ex. DEP, service E-Ordonnance Suisse)",
      hinServices:
        "Utilisation des services HIN tels que HIN Mail, HIN Sign",
      emailExample: "L’adresse e-mail d’une eID personnelle peut par exemple ressembler à prénom.nom{'@'}hin.ch",
    },
    teamCard: {
      teamCardHeader: "eID d’équipe",
      multiplePersons: "Peut être partagée entre plusieurs personnes",
      limitedAccessData: "Accès impossible aux données sensibles (p. ex. DEP, service E-Ordonnance Suisse)",
      limitedAccessServices: "Accès limité aux services DEP",
      emailExample: "L’adresse e-mail d’une eID d’équipe peut par exemple ressembler à cabinet.exemple{'@'}hin.ch."
    },
    addButtons: {
      teamLinkText: "Ajouter une eID d’équipe",
      persLinkText: "Ajouter une eID personnelle",
      mpaLinkText: "Ajouter une eID personnelle pour AM / CMA / personnel de pharmacie",
    },
    firstPersonalForm: "eID personnelle (preneur de contrat / détenteur de l’ID)",
    formString: "",
    persFormName: "eID personnelle (détenteur de l’ID)",
    mpaFormString: "eID AM personnelle (détenteur de l’ID)",
    trashCanText: "Supprimer",
    whatIsEidPers: "Qu’est-ce qu’une eID personnelle?",
    whatIsEidTeam: "Qu’est-ce qu’une eID d’équipe?",
    whatIsEidLink: "https://support.hin.ch/fr/identite-hin/difference-identite-personnelle-identite-de-cabinet/",
    errors: {
      duplicateMailError: "Une même adresse e-mail ne peut pas être utilisée pour plusieurs eID. " +
        "L’adresse indiquée doit être personnelle, car nous l’utilisons pour générer le mot de passe.",
      hinMailExistingError: "L’adresse e-mail {currentHinEmail} est déjà attribuée à une eID HIN. " +
      "Veuillez prendre contact avec le {supportLink} afin de déterminer la marche à suivre.",
    },
  },
  emailForm: {
    forward: "Suivant",
    persHeader: "eID personnelle",
    mpaHeader: "eID personnelle pour AM / CMA / personnel de pharmacie",
    teamHeader: "eID d’équipe",
    infoTextMail:
      "Ces adresses sont des suggestions. Les adresses e-mail définitives seront vérifiées et attribuées ultérieurement. ",
    infoTextPleaseConsider: "Veuillez tenir compte des points suivants lors de la sélection de l’adresse e-mail pour votre eID personnelle:",
    infoTextFullName: "L’adresse e-mail doit impérativement contenir votre nom de famille complet",
    infoTextFirstName: "Vous pouvez au choix abréger votre prénom ou l’indiquer en entier",
    infoTextFantasyName: "Les noms fantaisistes ne sont pas autorisés",
    praxisSoftwareLabel:
      "Combiner l’adhésion avec le logiciel de cabinet suivant",
    praxisSoftwareInfo:
      "La combinaison avec le logiciel de cabinet a une influence sur le domaine de l’e-mail.",
    praxisSoftwareSelect: "Choisir un logiciel de cabinet",
    noPraxisSoftware: "no-software",
    praxisSoftwareOptions: {
      "no-software": "Ne pas connecter de logiciel de cabinet",
      softplus: "Softplus",
      physiosys: "Physiosys",
      sophisware: "Sophisware",
    },
  },
  offerForm: {
    errorModal: {
      header: "Une erreur est survenue",
      text: "Nous n’avons malheureusement pas pu établir votre offre. Veuillez contacter le {supportLink}.",
      errorCode: "Code d’erreur",
    },
    offerMismatchModal: {
      header: "Attention",
      intro: "Le nombre minimal/maximal d’eID personnelles et/ou / le nombre minimal/maximal "
        +"d’eID d’équipe – ou éventuellement seulement d’eID pour cette offre d’association – n’est pas rempli. "
        +"Veuillez commander le nombre nécessaire, faute de quoi le rabais ne s’appliquera pas. "
        +"De plus, le cas échéant, vous ne recevrez pas d’adresse e-mail avec l’extension de l’association. "
        +"Vous trouverez les conditions sur www.hin.???",
      numTeamIds: "d’eID d’équipe HIN",
      numTotPersIds: "d’eID personnelles (y c. AM)",
      numTotIds: "d’eID personnelles et d’équipe",
      minimum: "Minimum",
      maximum: "Maximum",
    },
    pageHeaders: {
      single: "Adhésion individuelle",
      collective: "Adhésion collective",
      collectiveAssociation: "Adhésion collective association",
    },
    tableHeaders: {
      countText: "Nombre",
      singlePriceText: "Prix unitaire",
      totalPriceText: "Prix total",
    },
    tableContentAdmission: {
      admissionCostText: "Coûts uniques",
      admissionChargeText: "Coûts d’admission uniques",
      associationBenefitText: "Avantage de l’offre pour association",
      admissionCostTotalText: "Total coûts uniques",
      admissionFeeText: "Frais d’admission",
      admissionDiscountText: "Avantage de l’offre pour association Association { name }",
    },
    tableContentAnnual: {
      annualCostText: "Coûts annuels",
      personalIdsText: "Cotisation annuelle eID personnelle",
      personalIdsDiscount: "Rabais",
      mpaIdsText: "Cotisation annuelle eID personnelle AM",
      teamIdsText: "Cotisation annuelle eID d’équipe HIN",
      annualDiscountText: "Avantage de l’offre pour association Association { name }",
      annualCostTotalText: "Total coûts annuels",
    },
    sponsorText: "Sponsor:",
    totalAmountText: "Montant total",
    btnForward: "Accepter et continuer",
    followUp: "Suivi des informations contractuelles",
    retired: "Je suis à la retraite",
    currency: "CHF",
    mutationFeeText: "Frais de mutation",
  },
  contractDetail: {
    button: {
      back: "Retour",
      forward: "Conclure le contrat",
      savingInProgressTitle: "Enregistrer..",
      savingInProgressText: "Veuillez patienter",
      eIdErrorsTitle: "Données erronées à l’étape 3",
      eIdErrorsText:
        "Les adresses e-mail indiquées ne correspondent pas aux exigences."
        + " Veuillez vérifier vos données à l’étape 3.",
      formErrorsTitle: "Données manquantes",
      formErrorsText: "Veuillez remplir tous les champs marqués d’un astérisque (*).",
      savingErrorsTitle: "Erreur lors de l’enregistrement",
      savingErrorsText:
        "Une erreur est survenue lors de l’enregistrement. Veuillez vérifier votre connexion Internet."
        + " Si le problème persiste, contactez le { supportLink }.",
      savingTitle: "Données OK",
      savingText: "Les données sont enregistrées. Vous allez être redirigé.",
    },
    title: "Contrat",
    noBelongToOrg: "Je n’appartiens à aucune organisation / institution",
    forOrganization: {
      title: "Organisation",
      titleBusinessContactPerson: "Personne de contact",
      titleHint: "(Preneur de contrat)",
      orgName: "Nom de l’organisation",
      email: "Adresse e-mail de l’organisation (actuelle)",
      tel: "Téléphone de l’organisation",
      gln: "GLN de l’organisation",
      glnLookupPending: "Veuillez patienter, nous recherchons votre GLN dans RefData",
      businessContactPhone: "Téléphone portable",

      cbAckLegalOrgSingleMembership:
        "Par la présente, je confirme" +
        " que les conditions de base pour la conclusion du contrat sont remplies" +
        " et que les informations fournies sont exactes." +
        " En outre, j’ai lu et accepté les documents contractuels suivants: {cbAckLegalOrgSingleMembershipContract}",
      cbAckLegalOrgSingleMembershipContract: "Contrat-cadre Adhésion individuelle",
      cbAckLegalOrgSingleMembershipContractLink: "https://www.hin.ch/files/pdf1/contrat-cadre_adhesion_individuelle_a_hin.pdf",
      cbAckLegalOrgSinglePersonallyResponsible:
        "J’ai pris note" +
        " qu’en concluant ce contrat en tant que membre individuel HIN," +
        " je suis personnellement responsable de la protection des données – et" +
        " non l’organisation indiquée.",

      cbAckLegalOrgCollectiveMembership:
        "Par la présente, je confirme" +
        " que les conditions de base pour la conclusion du" +
        " contrat sont remplies et que les informations fournies sont" +
        " exactes." +
        " En outre, j’ai lu et accepté les documents contractuels suivants: {cbAckLegalOrgCollectiveMembershipContract}",
        cbAckLegalOrgCollectiveMembershipContract: "Contrat-cadre Adhésion collective",
        cbAckLegalOrgCollectiveMembershipContractLink: "https://www.hin.ch/files/pdf1/contrat-cadre_adhesion_collective_a_hin.pdf",
    },
    forPersonal: {
      cbAckLegalPersSingleMembership:
        "Par la présente, je confirme" +
        " que les conditions de base pour la conclusion du" +
        " contrat sont remplies et que les informations fournies sont" +
        " exactes." +
        " En outre, j’ai lu et accepté les documents contractuels suivants: {cbAckLegalPersSingleMembershipContract}",
        cbAckLegalPersSingleMembershipContract: "Contrat-cadre Adhésion individuelle",
        cbAckLegalPersSingleMembershipContractLink: "https://www.hin.ch/files/pdf1/hin_rahmenvertrag_einzelmitgliedschaft.pdf",
      cbAckLegalPersSinglePersonallyResponsible:
        "J’ai pris note" +
        " qu’en concluant ce contrat en tant que" +
        " membre individuel HIN, je suis personnellement responsable de la protection des données.",

      cbAckLegalPersCollectiveMembership:
        "Par la présente, je confirme" +
        " que les conditions de base pour la conclusion du" +
        " contrat sont remplies et que les informations fournies sont" +
        " exactes." +
        " En outre, j’ai lu et accepté les documents contractuels suivants: {cbAckLegalPersCollectiveMembershipContract}",
        cbAckLegalPersCollectiveMembershipContract: "Contrat-cadre Adhésion collective",
        cbAckLegalPersCollectiveMembershipContractLink: "https://www.hin.ch/files/pdf1/contrat-cadre_adhesion_collective_a_hin.pdf",
      cbAckLegalPersCollectivePersonallyResponsible:
        "J’ai pris note" +
        " que sans indication d’une organisation" +
        " (personne morale), je suis personnellement responsable" +
        " de la protection des données pour le contrat conclu.",
    },
    inputs: {
      emailCorrespondence: "Adresse e-mail pour la correspondance (actuelle)",
      orderCovercardService: "Je souhaite activer le {covercardService}",
      covercardService: "Service Covercard",
      covercardServiceLink: "https://www.hin.ch/fr/services/hin-service-covercard/service-covercard-hin.cfm",
      covercardTitle: "Covercard",
      covercardItemPrice: "{ amount } CHF",
      cardReaderInfoText: "Découvrez {cardReaderHere}, la liste des lecteurs de cartes pris en charge par votre logiciel de cabinet.",
      cardReaderlink: "https://www.hin.ch/files/pdf1/logiciels-pour-cabinets-medicaux-covercard.pdf",
      cardReaderHere: "ici"
    },
    addressAdditional: "Complément d’adresse",
    organizationType: "Type d’organisation",
  },
  furtherInformation: {
    glnInfoUrl: "https://www.refdata.ch/fr/partenaires/requete/base-de-donnees-des-partenaires-gln",
    button: {
      back: {
        warningTitle: "Retour au contrat impossible",
        warningText:
          "Les données relatives au contrat ne peuvent plus être modifiées. Veuillez terminer le processus de commande " +
          "(y c. l’identification vidéo) et signalez-nous tout changement par téléphone au {backPhoneNumber} " +
          "ou via {backContactForm}.",
        backPhoneNumber: "0848 830 740",
        backPhoneNumberLink: "Tél.:0848%20830%20740",
        backContactForm: "Formulaire de contact",
      },
      formErrorsTitle: "Données manquantes",
      formErrorsText: "Veuillez remplir tous les champs marqués d’un astérisque (*).",
      savingErrorsTitle: "Erreur lors de l’enregistrement",
      savingErrorsText:
        "Une erreur est survenue lors de l’enregistrement. Veuillez vérifier votre connexion Internet."
        + " Si le problème persiste, contactez le { supportLink }. ",
      savingInProgressTitle: "Enregistrer..",
      savingInProgressText: "Veuillez patienter",
      savingTitle: "Données OK",
      savingText: "Les données sont enregistrées. Vous allez être redirigé.",
    },
    glnLookupPending:
      "..Veuillez patienter un instant, nous recherchons votre GLN dans les registres",
    person: {
      // removed (Maximum: 3) from the key so the text fits in the button
      addJobName: "Ajouter une autre profession de santé",
      title: "Données personnelles",
      header: "eID personnelle pour",
      gln: "GLN personnel",
      accessGln: "Pour accéder au DEP, il faut impérativement indiquer un GLN.",
      mpaEpdGlnHint:
        "Pour accéder au DEP, il est obligatoire de fournir un GLN." +
        " Vous pouvez terminer le processus de commande sans indiquer de GLN." +
        " Si vous avez besoin de l’accès au DEP à une date ultérieure," +
        " le GLN peut être indiqué ultérieurement.",
      ackLegal:
        "Par la présente, je confirme que les informations fournies sont exactes" +
        " et j’accepte la {privacyPolicy}.",
      privacyPolicy: "Déclaration de protection des données de HIN",
      privacyPolicyLink: "https://www.hin.ch/fr/declaration-de-protection-des-donnees-de-health-info-net-ag-hin/",
      button: {
        forward: "Suivant",
      },
    },
    infoText:
      "Pour accéder au DEP, un GLN est obligatoire. Indiquez impérativement votre GLN " +
      "si vous en avez un. ",
    infoTextFindGln: "Rechercher GLN",
    cardReaderOrder: "Lecteur payant",
    practiceSoftware: "Quel logiciel de cabinet utilisez-vous?",
    tokenExpiredTitle: "Date limite d’inscription dépassée",
    tokenExpiredText:
      "Votre fenêtre de connexion a expiré. Veuillez contacter le {supportLink}.",
  },
  videoIdentification: {
    loading: "Veuillez patienter..",
    errorTitle: "Une erreur s’est produite lors du chargement de l’identification vidéo",
    errorText:
      "Veuillez réessayer. Si le problème persiste," +
      " veuillez contacter le {supportLink}.",
    title: "identification vidéo",
    introText:
      "Lors de l’{title}, vos données seront " +
      "vérifiées à l’aide d’un document d’identité valide.",
    introLink: "En savoir plus sur la procédure d’identification vidéo",
    availability: "Accessibilité",
    businessHours: "Du lundi au samedi, de 7h00 à 22h00",
    listHeader:
      "L’identification vidéo dure environ 10 minutes. Il vous faut pour cela:",
    button: {
      back: "Retour",
      forward: "Commencer l’identification",
    },
    list: {
      computerOrSmartphone: "Ordinateur ou smartphone avec microphone et caméra",
      validIdentityDocument: "Document d’identification valide",
      quietBrightPlace: "Un endroit calme et lumineux",
    },
    documentInfo: {
      ifUsingSmartphone:
        "Si vous utilisez un smartphone:" +
        " veuillez vous assurer que vous êtes uniquement connecté en 4G et que la fonction Wi-Fi est désactivée.",
      alsoMakeSure:
      "Veuillez également vous assurer que votre téléphone est à jour.",
      withSwissCitizenship:
        "Personnes de nationalité suisse:" +
        " carte d’identité suisse ou passeport suisse",
      withoutSwissCitizenship:
        "Personnes sans nationalité suisse:" +
        " document d’identité valable du pays d’origine avec titre de séjour",
    },
  },
  identSuccess: {
    withEids: {
      title:
        "Invitation pour identité personnelle | Invitations pour identités personnelles",
      intro:
        "Vous avez commandé une identité personnelle (eID) pour une autre personne." +
        " Veuillez informer cette personne de la suite des événements" +
        " et envoyer une invitation pour l’identification vidéo." +
        " | Vous avez commandé plusieurs identités personnelles (eID) pour d’autres personnes." +
        " Veuillez informer ces personnes de la marche à suivre et envoyer" +
        " une invitation pour l’identification vidéo.",
      tableExplanation:
        "Envoyer une invitation par e-mail | Envoyer des invitations par e-mail",
      infoByEmail:
      "Après le traitement de votre eID, les données d’accès vous seront envoyées par e-mail."
        // "Vous recevrez des informations sur la procédure à suivre" +
        // " après l’envoi de l’invitation par e-mail à { email }." +
        // " | Vous recevrez des informations sur la procédure à suivre" +
        // " après l’envoi des invitations par e-mail à { email }.",
    },
    withoutEids: {
      title: "Identification vidéo réussie",
      text:
        "Vous avez réussi l’identification vidéo." +
        " Nous vous avons envoyé des informations sur la suite de la procédure par e-mail.",
    },
    error: {
      title: "Une erreur est survenue lors de la transmission des données.",
      restart: "Veuillez relancer l’identification vidéo:",
      text:
        "Vous pouvez également procéder à l’identification ultérieurement." +
        " Nous vous avons envoyé le lien correspondant à {email}." +
        " ",
      restartIdent: "Ici",
      noMatch:
        "Vos données n’ont pas été trouvées. Veuillez vous adresser" +
        " au {supportLink}.",
      supportLink: "https://support.hin.ch/fr/",
      support: "Support HIN",
    },
    loading: {
      title: "Veuillez patienter",
      text: "Veuillez patienter un instant, vous allez être redirigé(e).",
    },
    table: {
      description:
        "Tableau listant les eID qui reçoivent un e-mail d’invitation",
      header: {
        name: "Détenteur de l’ID",
        email: "Adresse e-mail",
        subject: "Objet de l’e-mail",
      },
      body: {
        subject: "Activation de votre eID personnelle",
      },
    },
    button: {
      sendInvitationMails:
        "Envoyer un e-mail et fermer la fenêtre" +
        " | Envoyer des e-mails et fermer la fenêtre",
      invitationMailsSent:
        "E-mail envoyé. Vous pouvez maintenant fermer la fenêtre" +
        " | E-mails envoyés. Vous pouvez maintenant fermer la fenêtre",
      invitationMailsFailure:
        "Erreur lors de l’envoi. Veuillez contacter le {supportLink}.",
      close: "Vous pouvez maintenant fermer la fenêtre",
    },
  },
  // Generic keys for forms
  form: {
    button: {
      forward: "Suivant",
    },
    association: "Association professionnelle",
    associationMemberNumber: "Numéro de membre Association professionnelle",
    birthdate: "Date de naissance",
    correspondenceLanguage: "Langue de correspondance",
    errorMessage: "Les champs marqués en rouge sont vides ou contiennent des informations erronées.",
    street: "Rue",
    streetNo: "N°",
    city: "Lieu",
    zipCode: "NPA",
    selectionPlease: "(Veuillez sélectionner)",
    country: "Pays",
    glnErrors: {
      onlyDigits: "Le GLN ne doit contenir que des chiffres.",
      noMatch: "Le GLN saisi n’a pas été trouvé dans le registre.",
      noMatchPerson:
        "Aucune entrée trouvée. Indiquez un GLN valide ou laissez le champ vide.",
      tooShort: "Le GLN indiqué est trop court.",
      tooLong: "Le GLN indiqué est trop long.",
      unknownError:
        "Le GLN indiqué n’a pas pu être trouvé.",
    },
    glnText:
      "Le Global Location Number (GLN) sert à une identification univoque" +
      " les personnes physiques et morales (p. ex. professionnel de la santé, organisation de santé)" +
      " et les lieux physiques (p. ex. bâtiment, entrepôt). {0}",
    glnTitle: "GLN",
    glnUrlText: "Informations complémentaires",
    profession: "Profession / Profession de santé",
    profTitle: "Titre de spécialiste",
    salutation: "Civilité",
    salTitle: "Titre académique",
    mobilePhone: "Téléphone portable",
    name: "Nom",
    firstName: "Prénom",
    lastName: "Nom",
    billingAddress: "Adresse de facturation",
    differentInvoiceAddress: "Adresse de facturation différente",
    feedbackMessageTitle: "Remarque",
    feedbackMessageLabel: "Vous souhaitez nous faire part de quelque chose?",
    contractTerms: "Dispositions contractuelles",
    errors: {
      "Failed string anyNv missing parameter value":
        "Veuillez faire votre choix.",
      "Missing property": "Veuillez remplir",
      codeLabel: "Code d’erreur: ",
      specialCharacters: "n’utilisez pas de caractères spéciaux ou de chiffres",
      missingProperty: "Veuillez saisir {label}",
      unknownMailServer: "Le domaine de messagerie (p. ex. {'@'}hin.ch) ne peut pas être modifié.",
      invalidEmail: "Veuillez saisir une adresse e-mail valide.",
      checkRegulations: "Veuillez accepter les termes du contrat",
      invalidTelNrSwitzerland: "Veuillez saisir un numéro de téléphone à 9 chiffres",
      invalidTelNrLiechtenstein: "Veuillez saisir un numéro de téléphone à 7 chiffres",
      invalidTelNrOther: "Veuillez saisir un numéro de téléphone à 7-13 chiffres",
    },
  },
  Fernwartung:
    "TODO: make key English, Fill in Text. I have just put the key here to silence a warning",
  countries: {
    CH: "Suisse",
  },
  phoneCountryCodes: {
    CH: "CH +41",
  },
  register: {
    infoFoundInRegister: "Entrée trouvée dans {register}",
  },
  sidebar: {
    steps: {
      membership: "Indications pour la commande",
      identities: "Identités (eID)",
      identitiesAndMail: "Identités (eID) et adresses e-mail",
      hinEmails: "Adresses e-mail",
      hinEmailSelect: "Sélectionner les adresses e-mail",
      offer: "Offre",
      contractDetail: "Données contractuelles",
      furtherInfo: "Données personnelles",
      videoIdentification: "Identification vidéo",
    },
  },
};
